import React from 'react';
import  { useRef } from 'react'
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';


import logo from '../../assets/images/logo/logo.png'



function Footer2(props) {
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_rg0r0ym', 'template_fqg7w91', form.current, {
        publicKey: 'cxIEUTKyfzeItouNn',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          form.current.reset();
          Swal.fire({
            title: "Success!",
            text: "Newsletter subscribed successfully",
            icon: "success"
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };


    return (
        <footer className="footer style-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="footer__main">

                                    <div className="info-left">
                                        <Link to="/" className="logo"><img src={logo} alt="Trigeminal" /></Link>
                                        <ul className="list-social">
                                            <li><a target='_blank' href='https://www.facebook.com/people/Trigeminal-AI/pfbid02BuSSqi7dvuGWss3gjdaz4FAsFdGfCSiEn4kVSK5Tb2SFNXvAXwDvZsQTpy4uKjEwl/?mibextid=LQQJ4d'>
                                                <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.57969 9.03953C2.51969 9.03953 1.19969 9.03953 0.599688 9.03953C0.279688 9.03953 0.179688 8.91953 0.179688 8.61953C0.179688 7.81953 0.179688 6.99953 0.179688 6.19953C0.179688 5.87953 0.299688 5.77953 0.599688 5.77953H2.57969C2.57969 5.71953 2.57969 4.55953 2.57969 4.01953C2.57969 3.21953 2.71969 2.45953 3.11969 1.75953C3.53969 1.03953 4.13969 0.559531 4.89969 0.279531C5.39969 0.0995311 5.89969 0.0195312 6.43969 0.0195312H8.39969C8.67969 0.0195312 8.79969 0.139531 8.79969 0.419531V2.69953C8.79969 2.97953 8.67969 3.09953 8.39969 3.09953C7.85969 3.09953 7.31969 3.09953 6.77969 3.11953C6.23969 3.11953 5.95969 3.37953 5.95969 3.93953C5.93969 4.53953 5.95969 5.11953 5.95969 5.73953H8.27969C8.59969 5.73953 8.71969 5.85953 8.71969 6.17953V8.59953C8.71969 8.91953 8.61969 9.01953 8.27969 9.01953C7.55969 9.01953 6.01969 9.01953 5.95969 9.01953V15.5395C5.95969 15.8795 5.85969 15.9995 5.49969 15.9995C4.65969 15.9995 3.83969 15.9995 2.99969 15.9995C2.69969 15.9995 2.57969 15.8795 2.57969 15.5795C2.57969 13.4795 2.57969 9.09953 2.57969 9.03953Z" fill="white"/>
                                                </svg>    
                                            </a></li>
                                            <li><a  target='_blank' href='https://www.instagram.com/triadsbytrigeminal_ai/?igsh=MTB1Z2Vkdm9mdXQ1dQ%3D' >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="white" d="M12.001 9a3 3 0 1 0 0 6a3 3 0 0 0 0-6m0-2a5 5 0 1 1 0 10a5 5 0 0 1 0-10m6.5-.25a1.25 1.25 0 0 1-2.5 0a1.25 1.25 0 0 1 2.5 0M12.001 4c-2.474 0-2.878.007-4.029.058c-.784.037-1.31.142-1.798.332a2.886 2.886 0 0 0-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.007 9.075 4 9.461 4 12c0 2.475.007 2.878.058 4.029c.037.783.142 1.31.331 1.797c.17.435.37.748.702 1.08c.337.336.65.537 1.08.703c.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.475 0 2.878-.007 4.029-.058c.782-.037 1.308-.142 1.797-.331a2.91 2.91 0 0 0 1.08-.703c.337-.336.538-.649.704-1.08c.19-.492.296-1.018.332-1.8c.052-1.103.058-1.49.058-4.028c0-2.474-.007-2.878-.058-4.029c-.037-.782-.143-1.31-.332-1.798a2.912 2.912 0 0 0-.703-1.08a2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.926 4.006 14.54 4 12 4m0-2c2.717 0 3.056.01 4.123.06c1.064.05 1.79.217 2.427.465c.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428c.047 1.066.06 1.405.06 4.122c0 2.717-.01 3.056-.06 4.122c-.05 1.065-.218 1.79-.465 2.428a4.884 4.884 0 0 1-1.153 1.772a4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.427.465c-1.067.047-1.406.06-4.123.06c-2.717 0-3.056-.01-4.123-.06c-1.064-.05-1.789-.218-2.427-.465a4.89 4.89 0 0 1-1.772-1.153a4.905 4.905 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.012 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122c.05-1.065.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.637-.248 1.362-.415 2.427-.465C8.945 2.013 9.284 2 12.001 2"/></svg>                                            </a></li>
                                            <li><a target='_blank' href="https://www.linkedin.com/company/trigeminal/">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.0006 14V8.87249C14.0006 6.35249 13.4581 4.42749 10.5181 4.42749C9.10062 4.42749 8.15563 5.19749 7.77063 5.93249H7.73563V4.65499H4.95312V14H7.85813V9.36249C7.85813 8.13749 8.08563 6.96499 9.59063 6.96499C11.0781 6.96499 11.0956 8.34749 11.0956 9.43249V13.9825H14.0006V14Z" fill="white"/>
                                                    <path d="M0.226562 4.65479H3.13156V13.9998H0.226562V4.65479Z" fill="white"/>
                                                    <path d="M1.68 0C0.7525 0 0 0.7525 0 1.68C0 2.6075 0.7525 3.3775 1.68 3.3775C2.6075 3.3775 3.36 2.6075 3.36 1.68C3.36 0.7525 2.6075 0 1.68 0Z" fill="white"/>
                                                    </svg>
                                                    
                                            </a></li>
                                            <li><a target='_blank' href='https://www.youtube.com/@TrigeminalAI'>
                                                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.2347 1.9639C17.1458 1.22216 16.4468 0.510897 15.7154 0.415609C11.2555 -0.138536 6.7457 -0.138536 2.28731 0.415609C1.55533 0.51069 0.856308 1.22216 0.76739 1.9639C0.452537 4.68236 0.452537 7.31818 0.76739 10.036C0.856308 10.7778 1.55533 11.4897 2.28731 11.5843C6.7457 12.1384 11.2557 12.1384 15.7154 11.5843C16.4468 11.4898 17.1458 10.7778 17.2347 10.036C17.5496 7.31842 17.5496 4.68236 17.2347 1.9639ZM7.58931 8.82375V3.17703L11.8243 6.00049L7.58931 8.82375Z" fill="white"/>
                                                </svg>    
                                            </a></li>
                                        </ul>
                                    </div>

                                    <div className="right">
                                        <div className="top">
                                            <ul className="widget">
                                                <li><h5 className="title">Quick link</h5></li>
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/triads">TriADS</Link></li>
                                                <li><Link to="/services">Services</Link></li>
                                                <li><Link to="/products">Products</Link></li>
                                                <li><Link to="/about">About Us</Link></li>
                                                <li><Link to="/blog">Blog</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                            </ul>
                                            <ul className="widget">
                                                <li><h5 className="title">SAP Services</h5></li>
                                                <li><Link to="/hana-services">S/4 HANA </Link></li>
                                                <li><Link to="/implementation">Implementation </Link></li>
                                                <li><Link to="/upgradation">Upgradation</Link></li>
                                                <li><Link to="/consulting">Consulting</Link></li>
                                                <li><Link to="/support">Support</Link></li>
                                            </ul>
                
                                            <div className="widget">
                                                <h5 className="title">Newsletter</h5>
                
                                                <form  ref={form} onSubmit={sendEmail}  className="form">
                                                    <p>Get updated with news, tips & tricks</p>
                                                    <div className="form-group">
                                                        <input type="email" name="email_address" id="email_address" className="form-control" placeholder="Your email" required/>
                                                        <button type='submit' className=""><svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.5 1.5L19 9M19 9L11.5 16.5M19 9H1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                            </button>
                                                        
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>

                                        <div className="bottom">
                                        <p>© 2024 <Link className='' style={{fontSize:"12px",color: "rgba(255, 255, 255, 0.3)"}} to="/">Pearle’s Diagnostics Pvt. Ltd</Link>, Inc. All rights reserved.</p>
                                            
                                            <ul className="list">
                                                <li><Link to="/terms">Terms & Conditions</Link></li>
                                                <li><Link to="/privacy">Privacy Policy</Link></li>
                                                <li><Link to="/refund">Refund Policy</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </footer>
    );
}

export default Footer2;